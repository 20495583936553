import * as React from 'react';
import Head from 'next/head';

import GlobalNotification from './GlobalNotification';

const LayoutPage = ({ children }) => (
  <>
    <Head>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta
        name="insight-app-sec-validation"
        content="e034f093-eab3-4d67-811a-bece3b34b10a"
      />
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href="/static/img/favicon.png"
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
      />
      <link rel="stylesheet" href="/static/ionicons/css/ionicons.min.css" />
      <link rel="stylesheet" href="/static/fontawesome/css/all.min.css" />
      <link rel="stylesheet" href="/static/css/dashforge.css" />
    </Head>

    <GlobalNotification />

    {children}
  </>
);

export default LayoutPage;
