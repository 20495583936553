import { connect } from 'react-redux';
import * as React from 'react';
import Toast from 'react-bootstrap/Toast';

import {
  NotificationSelector,
  NotificationAction,
} from '../state/notification';

const connectToRedux = connect(
  (state) => ({
    notifications: NotificationSelector.getNotifications(state),
  }),
  {
    dismiss: NotificationAction.dismiss,
  }
);

function GlobalNotification(props) {
  const { notifications = [], dismiss } = props;
  const hasNotifications = notifications.length > 0;
  return (
    <div className="notification-container d-flex justify-content-center">
      {hasNotifications && (
        <div className="notification-content">
          {notifications.map((n) => (
            <Toast
              key={n.id}
              show
              onClose={() => {
                dismiss(n.id);
              }}
              autohide={n.autohide}
              delay={n.delay}
            >
              {/* <Toast.Header className="bg-gray-900">
              <strong className="mr-auto tx-white">{n.title}</strong>
            </Toast.Header> */}
              <Toast.Body
                className="bg-gray-900 tx-white"
                onClick={() => dismiss(n.id)}
              >
                {n.body}
              </Toast.Body>
            </Toast>
          ))}
        </div>
      )}

      <style jsx>{`
        .notification-container {
          position: fixed;
          top: 0;
          left: 0;
          z-index: 999;
          width: 100%;
        }
        .notification-content {
          width: 290px;
          margin-bottom: 20px;
          margin-top: 20px;
        }
      `}</style>
    </div>
  );
}

export default connectToRedux(GlobalNotification);
